<template>
    <div class="app-body">
        <div class="a-flex-rsbc a-ptb-6">
            <bread-crumb></bread-crumb>
            <div class="a-flex-rcc">
                <el-button icon="el-icon-upload2" class="s-btn-exp" @click="exportfile">导出</el-button>
            </div>
        </div>
        <el-card class="el-main">
            <!-- <div class="a-flex-rfsbl a-fw-b">
                <span class="a-fs-16 a-c-master">2024-04-02</span>
            </div> -->
            
            <le-pagview class="a-mt-10" ref="financeZZ" @setData="setTableData" :pageParam="pageParam" :pagerCount="5" :isFixed="true" :tableRef="$refs.financeZZList">
                <el-table ref="financeZZList" :data="tableData" :highlight-current-row="true" v-sticky="{ top: 0, parent:'.el-card__body' }" style="width: 100%">
                    <el-table-column prop="companyName" :label="tripartiteType == 2?'云闪付订单号':'微信订单号'" min-width="120">
                        <template slot-scope="{ row }">
                            <span>{{ row.transactionId?row.transactionId:'-' }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="paymentName" label="退款订单号" min-width="120">
                        <template slot-scope="{ row }">
                            <span>{{ row.refundTransactionId?row.refundTransactionId:'-' }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="incomePrice" label="订单号" min-width="120">
                        <template slot-scope="{ row }">
                            <span>{{ row.orderSn?row.orderSn:'-' }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="startDate" label="时间" min-width="120">
                        <template slot-scope="{ row }">
                            <span>{{ $getDay.getTimeStampToStr(row.payTime) }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="endDate" label="微信" min-width="120">
                        <el-table-column prop="endDate" label="订单金额" min-width="120">
                            <template slot-scope="{ row }">
                                <span>{{ util.numFormatNotValue(row.tripartiteAmount) }}</span>
                            </template>
                        </el-table-column>
                        <el-table-column prop="endDate" label="商户号" min-width="120">
                            <template slot-scope="{ row }">
                                <span>{{ row.subMchId?row.subMchId:'-'  }}</span>
                            </template>
                        </el-table-column>
                        <el-table-column prop="endDate" label="类型" min-width="120">
                            <template slot-scope="{ row }">
                                <span>{{ row.tradeType | initDic(tradeTypeDic) }}</span>
                            </template>
                        </el-table-column>
                    </el-table-column>
                    <el-table-column prop="endDate" label="支付流水" min-width="120">
                        <el-table-column prop="endDate" label="金额" min-width="120">
                            <template slot-scope="{ row }">
                                <span>{{ util.numFormatNotValue(row.turnoverAmount) }}</span>
                            </template>
                        </el-table-column>
                        <el-table-column prop="endDate" label="类型" min-width="120">
                            <template slot-scope="{ row }">
                                <span>{{ row.recordType | initDic(recordTypeDic) }}</span>
                            </template>
                        </el-table-column>
                    </el-table-column>
                    <el-table-column prop="endDate" label="订单" min-width="120">
                        <el-table-column prop="endDate" label="支付金额(线上支付部分)" min-width="120">
                            <template slot-scope="{ row }">
                                <span v-if="row.recordType == 0">{{ util.numFormatNotValue(row.orderAmount) }}</span>
                                <span v-else>-</span>
                            </template>
                        </el-table-column>
                        <el-table-column prop="endDate" label="退款金额" min-width="120">
                            <template slot-scope="{ row }">
                                <span v-if="row.recordType == 1">{{ util.numFormatNotValue(row.orderAmount) }}</span>
                                <span v-else>-</span>
                            </template>
                        </el-table-column>
                        <el-table-column prop="endDate" label="订单类型" min-width="120">
                            <template slot-scope="{ row }">
                                <span>{{ row.orderType | initDic(orderTypeDic) }}</span>
                            </template>
                        </el-table-column>
                    </el-table-column>
                </el-table>
                <template slot="headerContent" slot-scope="{ val }" >
                    <div class="a-flex-rsbfs a-fs-12 a-flex-wrap a-w-100" style="margin-bottom: 10px">
                        <div class="a-flex-cfsfs">
                            <span class="countTitle">三方支付</span>
                            <div class="a-flex-rfsbl a-fw-700">
                                <span>收入:￥</span>
                                <span class="a-fs-18">{{ util.numFormat(val.tripartiteIncome) }}</span>
                                <span class="a-ml-15">支出:￥</span>
                                <span class="a-fs-18" style="margin-right: 15px">{{ util.numFormat(val.tripartiteExpenses) }}</span>
                                <span>合计:￥</span>
                                <span class="a-fs-18">{{ util.numFormat(val.tripartiteIncome - val.tripartiteExpenses) }}</span>
                            </div>
                        </div>
                        <div class="a-flex-cfsfs">
                            <span class="countTitle">收支流水</span>
                            <div class="a-flex-rfsbl a-fw-700">
                                <span>收入:￥</span>
                                <span class="a-fs-18">{{ util.numFormat(val.turnoverIncome) }}</span>
                                <span class="a-ml-15">支出:￥</span>
                                <span class="a-fs-18" style="margin-right: 15px">{{ util.numFormat(val.turnoverExpenses) }}</span>
                                <span>合计:￥</span>
                                <span class="a-fs-18">{{ util.numFormat(val.turnoverIncome - val.turnoverExpenses) }}</span>
                            </div>
                        </div>
                        <div class="a-flex-cfsfs">
                            <span class="countTitle">订单</span>
                            <div class="a-flex-rfsbl a-fw-700">
                                <span>收入:￥</span>
                                <span class="a-fs-18">{{ util.numFormat(val.orderIncome) }}</span>
                                <span class="a-ml-15">支出:￥</span>
                                <span class="a-fs-18" style="margin-right: 15px">{{ util.numFormat(val.orderExpenses) }}</span>
                                <span>合计:￥</span>
                                <span class="a-fs-18">{{ util.numFormat(val.orderIncome - val.orderExpenses) }}</span>
                            </div>
                        </div>
                    </div>
                </template>
                <template slot="footerContent" slot-scope="{ val }" >
                    <span class="a-fw-700">日期：{{ startDate || '-' }} 至 {{ endDate || '-' }}</span>
                </template>
            </le-pagview>
        </el-card>
    </div>
</template>

<script>
    import util from '../../../src/utils/util'
    export default {
        data () {
            return {
                tableData: [],
                util: util,
                startDate: '',
                endDate: '',
                companyMerchantId: '',
                companyMerchantName: '',
                tripartiteType: '',
                pageParam: {
                    url: this.$Config.apiUrl.reconciliationOrderPage,
                    method: "post",
                    params: {
                        companyMerchantId: '',
                        endDay: "",
                        startDay: '',
                        tripartiteType: '',
                    },
                    freshCtrl: 1,
                },
                choiceDate: '',
                pickerOptions: {
                    onPick: ({ maxDate, minDate }) => {
                        this.choiceDate = minDate.getTime()
                        if (maxDate) this.choiceDate = ''
                    },
                    disabledDate: (time) => {
                        if (this.choiceDate) {
                            const one = 92 * 24 * 3600 * 1000
                            const minTime = this.choiceDate - one
                            const maxTime = this.choiceDate + one
                            return time.getTime() < minTime || time.getTime() > maxTime
                        }else{
                            return ''
                        }
                    },
                },
                tradeTypeDic: [],
                recordTypeDic: [],
                orderTypeDic: []
            }
        },
        created () {
            this.$getDic('eventType').then(res=>{ this.tradeTypeDic = res; })
            this.$getDic('pecodeType').then(res=>{ this.recordTypeDic = res; })
            this.$getDic('orderType').then(res=>{ this.orderTypeDic = res; })
            this.startDate = this.$route.query.startDate
            this.endDate = this.$route.query.endDate
            this.companyMerchantId = this.$route.query.companyMerchantId
            this.companyMerchantName = this.$route.query.companyMerchantName
            this.tripartiteType = this.$route.query.tripartiteType
            this.pageParam.params = {
                companyMerchantId: this.companyMerchantId,
                endDay: this.endDate,
                startDay: this.startDate,
                tripartiteType: this.tripartiteType
            };
        },
        activated () {
            this.pageParam.freshCtrl++;
        },
        methods: {
            //获取设备列表
            setTableData(data) {
                this.tableData = data;
            },
            handlerRest() {
                this.pageParam.params = {
                    companyMerchantId: this.companyMerchantId,
                    endDay: this.endDate,
                    startDay: this.startDate,
                    tripartiteType: this.tripartiteType
                };
                this.$refs['financeZZList'].clearFilter()
                this.handlerSearch()
            },
            handlerSearch() {
                this.$refs['financeZZ'].pageNum = 1
                this.pageParam.freshCtrl++;
            },
            exportfile () {
                this.$exfile({
                    code: 22,
                    fileName: this.pageParam.params.startDay + ' 至 ' + this.pageParam.params.endDay + this.companyMerchantName + ' 数据扎帐表导出',
                    startTime: this.pageParam.params.startDay,
                    endTime: this.pageParam.params.endDay,
                    params: this.pageParam.params
                })
            }
        }
    }
</script>

<style lang="scss" scoped>
    /deep/ .el-table__body-wrapper::-webkit-scrollbar {
		/*width: 0;宽度为0隐藏*/
		width: 0 !important;
		height: 0 !important;
	}
	/deep/ .el-table__fixed, /deep/ .el-table__fixed-right{
		height: 100% !important;
	}
    /deep/ .companySelect{
        .a-ml-20{
            margin-left: 10px !important;
        }
    }
    .countTitle{
        display: flex;
        justify-content: flex-start;
        align-items: center;
    }
    .countTitle::before{
        content: "";
        display: inline-block;
        width: 4px;
        height: 12px;
        background: #007af0;
        border-radius: 4px;
        margin-right: 6px;
    }
</style>